<template>
  <div class="basic-info">
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png"
             alt=""
             @click="goBack" />
        <span class="center">财务功能</span>
        <img src="../../assets/right.png"
             alt="" />
        <span class="center">即时交易列表</span>
        <img src="../../assets/right.png"
             alt="" />
        <span class="center">即时交易详情</span>
      </div>
    </div>
    <div class="text item"
         style="margin-top: 16px">
      <Divider :title="'支付方信息'"></Divider>
      <el-row>
        <el-col :span="12"
                class="text-align">支付方账号：{{ detailInfo.from_account }}</el-col>
        <el-col :span="12"
                class="text-align">支付方类型：{{ detailInfo.from_type | cardTypeVal }}</el-col>
        <el-col :span="12"
                class="text-align">支付方名称：{{ detailInfo.from_name }}</el-col>
        <el-col :span="12"
                class="text-align">支付方id：{{ detailInfo.from_id }}</el-col>
        <el-col :span="12"
                class="text-align">支付方手机号：{{ detailInfo.from_phone }}</el-col>
      </el-row>
      <Divider :title="'收款方信息'"></Divider>
      <el-row>
        <el-col :span="12"
                class="text-align">收款方账号：{{ detailInfo.to_account }}</el-col>
        <el-col :span="12"
                class="text-align">收款方类型：{{ detailInfo.to_type | cardTypeVal }}</el-col>
        <el-col :span="12"
                class="text-align">收款方名称：{{ detailInfo.to_name }}</el-col>
        <el-col :span="12"
                class="text-align">收款方id：{{ detailInfo.to_id }}</el-col>
        <el-col :span="12"
                class="text-align">收款方电话：{{ detailInfo.to_phone }}</el-col>
      </el-row>
      <Divider :title="'支付详情'"></Divider>
      <el-row>
        <el-col :span="12"
                class="text-align">编码：{{ detailInfo.code }}</el-col>
        <!-- <el-col :span="12" class="text-align"
          >分组编号针对一次支付多笔的关联项：{{ detailInfo.group }}</el-col
        > -->
        <!-- <el-col :span="12" class="text-align"
          >支付方账号：{{ detailInfo.from_account }}</el-col
        >
        <el-col :span="12" class="text-align"
          >支付方类型：{{ detailInfo.from_type | cardTypeVal }}</el-col
        >
        <el-col :span="12" class="text-align"
          >支付方名称：{{ detailInfo.from_name }}</el-col
        >
        <el-col :span="12" class="text-align"
          >支付方id：{{ detailInfo.from_id }}</el-col
        >
        <el-col :span="12" class="text-align"
          >支付方手机号：{{ detailInfo.from_phone }}</el-col
        > -->

        <!-- <el-col :span="12" class="text-align"
          >收款方账号：{{ detailInfo.to_account }}</el-col
        >
        <el-col :span="12" class="text-align"
          >收款方类型：{{ detailInfo.to_type | cardTypeVal }}</el-col
        >
        <el-col :span="12" class="text-align"
          >收款方名称：{{ detailInfo.to_name }}</el-col
        >
        <el-col :span="12" class="text-align"
          >收款方id：{{ detailInfo.to_id }}</el-col
        >
        <el-col :span="12" class="text-align"
          >收款方电话：{{ detailInfo.to_phone }}</el-col
        > -->

        <el-col :span="12"
                class="text-align">金额：{{ detailInfo.amount }}元</el-col>
        <el-col :span="12"
                class="text-align">是否自动提现：{{ detailInfo.auto_withdrawal ? "是" : "否" }}</el-col>
        <el-col :span="12"
                class="text-align">支付平台：{{ detailInfo.pay_platform | platformVal }}</el-col>
        <!-- <el-col :span="12" class="text-align"
          >关联的表：{{ detailInfo.link_class }}</el-col
        >
        <el-col :span="12" class="text-align"
          >关联的对象：{{ detailInfo.link_object }}</el-col
        > -->

        <el-col :span="12"
                class="text-align">备注：{{ detailInfo.remark }}</el-col>
        <el-col :span="12"
                class="text-align">支付状态：{{ detailInfo.state | payStateVal }}</el-col>
        <el-col :span="12"
                class="text-align">状态描述：{{ detailInfo.state_reason }}</el-col>

        <el-col :span="12"
                class="text-align">支付时间：{{ detailInfo.pay_time }}</el-col>
        <el-col :span="12"
                class="text-align">创建时间：{{ detailInfo.create_time }}</el-col>
        <!-- <el-col :span="12" class="text-align"
          >自动提现的卡参数{bank_name,bank_code,bank_branch,bank_line_no}：{{
            detailInfo.withdrawal_params
          }}</el-col
        > -->
      </el-row>
    </div>
  </div>
</template>
<script>
import { api } from '/src/api/base/index';
export const TradeDescribe = api('/trade')('trade.describe.json');
export default {
  components: {
    Divider: () => import('/src/components/divider.vue')
  },
  data () {
    return {
      code: '',
      detailInfo: {
        code: '',
        group: '',
        from_name: '',
        from_id: '',
        from_account: '',
        from_type: '',
        from_phone: '',
        to_name: '',
        to_id: '',
        to_account: '',
        to_type: '',
        to_phone: '',
        pay_platform: '',
        amount: '',
        link_class: '',
        link_object: '',
        remark: '',
        create_time: '',
        pay_time: '',
        state: '',
        state_reason: '',
        auto_withdrawal: '',
        withdrawal_params: ''
      }
    };
  },

  created () {
    this.code = this.$route.query.code;
    this.getInfo();
  },
  methods: {
    async getInfo () {
      this.detailInfo = await TradeDescribe({ code: this.code });
    },
    goBack () {
      window.history.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.text {
  font-size: 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}
.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
